import React from 'react';

export default function Navbar() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="bg-transparent bg-opacity-50 py-6 flex justify-center border-b-2 border-blue-300/75">
      <ul className="flex gap-3 md:gap-16 lg:gap-16 text-white text-lg md:text-xl lg:text-2xl font-bold">
        <button onClick={() => scrollToSection('about')} className="hover:text-blue-300/75">ABOUT</button>
        <button onClick={() => scrollToSection('projects')} className="hover:text-blue-300/75">PROJECTS</button>
        <button onClick={() => scrollToSection('skills')} className="hover:text-blue-300/75">SKILLS</button>
        <button onClick={() => scrollToSection('contact')} className="hover:text-blue-300/75">CONTACT</button>
      </ul>
    </nav>
  );
}
