import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Layout from './components/layout';
import HomePage from './components/homepage';
import { useEffect } from 'react';

export default function App() {

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, []);

    return (
        <Router>
            <Layout>
                <Routes>      
                    <Route path="/" element={<HomePage />} />
                    <Route path="*" element={<HomePage />} />
                </Routes>
            </Layout>
        </Router>
    );
}
