import React from 'react';

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-transparent p-4 bg-opacity-50 border-t-2 border-blue-300/75 text-center">
      <button onClick={scrollToTop} className="text-white text-lg md:text-xl lg:text-2xl font-bold hover:text-blue-300/75 mb-4">BACK TO TOP OF PAGE</button>
      <div className="text-white text-sm">
        &copy; {new Date().getFullYear()} Fredrik Vullum. All Rights Reserved.
      </div>
    </footer>
  );
}
